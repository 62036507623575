import React, { useState, useRef, useCallback, useEffect } from "react";
import ReactDOM from "react-dom";
import { LoadScript, GoogleMap, Polygon, DrawingManager, useGoogleMap } from "@react-google-maps/api";
import "./MapsArea.css"
import { useLocation, Link } from "react-router-dom";
import { getUpdateArealist, addNewArea, updateArea } from "store/actions";
import { getArealist as onGetArealist, getLaundries } from "store/actions";
import { useSelector, useDispatch } from "react-redux";
import { Col, Button, Row, Container, Label, Form, Card, CardBody, Input, 
  CardTitle, InputGroup, InputGroupText, Modal,
  ModalHeader,
  ModalBody 
} from "reactstrap"

import { useHistory } from 'react-router-dom';
import { postArea } from "helpers/LaundryHelper"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTags from "react-meta-tags";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { postWeb } from "helpers/api_helper";
import * as url from "helpers/url_helper"
import { myParseFloat } from "helpers/validationHelper";
import useScript from 'react-script-hook';

import Switch from "react-switch"
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import MultiSelect from "react-multiple-select-dropdown-lite";
import { postOrganizeArea } from "helpers/areaHelper";
import fileDownload from 'js-file-download'
import axios from "axios"
import { GoogleApiWrapper } from "google-maps-react";
import { mapLibraries } from "constants/app";
import Select from "react-select";


const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      Off
    </div>
  )
}

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      On
    </div>
  )
}

const HideSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 10
      }}
    >
      {" "}
      Hide
    </div>
  )
}

const ShowSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingLeft: 10
      }}
    >
      {" "}
      Show
    </div>
  )
}


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function MapsArea() {
  let history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  

  const { areas } = useSelector(state => ({
    areas: state.ecommerce.arealist,
  }));

  const { newarealist } = useSelector(state => ({
    newarealist: state.ecommerce.newAreaList,
  }));

  const { laundryList } = useSelector(state => ({
    laundryList: state.Laundries.laundryList
  }))

  const defaultLoyaltyPoints = {
    online: false,
    offline: false,
    earnPoints: 0,
    burnPoints: 0,
    redeemPackages: [
      {
        points: 0,
        value : 0,
        packageType : "SAR"
      }
    ]
  };

  // Store Polygon path in state
  const [polygonEditable, setPolygonEditable] = useState(false);
  const [allCoords, setAllCoords] = useState([])
  const [selectedArea, setSelectedArea] = useState({})
  const [allPolygon, setAllPolygon] = useState([])
  const [areaName, setAreaName] = useState("")
  const [minOrderAmount, setMinOrderAmount] = useState("");
  const [delivaryFees, setDelivaryFees] = useState("")
  const [createdPolygon, setCreatedPolygon] = useState(null)
  const [showForm, SetShowForm] = useState(false)

  const [formDivClass, setFormDivClass] = useState("d-none")
  const [drawingPolygone, setDrawingPolygone] = useState(null)
  const [error, setError] = useState({})
  const [checkPolygon, setCheckPolygon] = useState([]);
  const [areaEdit, setAreaEdit] = useState(false);
  const [userData, setUserData] = useState({});
  const [origionalPath, setOrigionalPath] = useState(null);
  const [undo, setUndo] = useState(false);
  const [areaStatus, setAreaStatus] = useState(true);
  const[googleLoaded, setGoogleLoaded] = useState(false);
  const [packageErrors, setPackageErrors] = [];
  const[loyaltyPoints, setLoyaltyPoints] = useState(defaultLoyaltyPoints);

  const [showTerms, setShowTerms] = useState(false);
  const [showMergeModal, setShowMergeModal] = useState(false);
  const [discount, setDiscount] = useState({online :"", onlineChecked :false, offline:"", offlineChecked :false});
  const [areaOption, setAreaOption] = useState([]);
  const [areaVal, setAreaVal] = useState([]);
  const [reorgProcessing, setReorgProcessing] = useState(false);
  const [showInActiveAreas, setShowInActiveAreas] = useState(false);
  const [showActiveAreas, setShowActiveAreas] = useState(true);
  const [arealist, setArealist] = useState([]);
  const [defaultLaundry, setdefaultLaundry] = useState("");
  const [defaultLaundryOld, setdefaultLaundryOld] = useState("");
  const [orderPercentage, setOrderPercentage] = useState(null);
  const [orderPercentageOld, setOrderPercentageOld] = useState(null);
  const [showDefaultLaundryAndPercentage, setShowDefaultLaundryAndPercentage] = useState(false);
  const [showOldDefaultLaundryAndPercentage, setShowOldDefaultLaundryAndPercentage] = useState(false)
  
  const { users } = useSelector(state => ({
    users: state.Users.users
  }));


  


  // Define refs for Polygon instance and listeners
  const polygonRef = useRef(null);
  const listenersRef = useRef([]);
  const mapRef = useRef(null);
  const editSteps = useRef({steps:[], lastStep:null});
  const undoBtn = useRef(null);
  const termsEditor = useRef(null);


  // Call setPath with new edited path
  const onEdit = (e) => {
    const _currentPath 
      = polygonRef.current
      .getPath()
      .getArray()
      .map(latLng => {
        return {lat: latLng.lat(), lng: latLng.lng()};
    });
    
    if(editSteps?.current?.lastStep){
      editSteps.current.steps.push(editSteps.current.lastStep);
      if(editSteps.current.steps.length ==1){
        undoBtn.current.disabled = false;
      }
    }
    
    editSteps.current.lastStep = _currentPath;
    
  }

  const UpdateArea = (data) => {
    const listAllData = {
      ...selectedArea, location: {
        coordinates: [data],
        _id: selectedArea?.location?._id,
        type: "Polygon"
      },
    }
    dispatch(getUpdateArealist(listAllData));
    //window.location.reload(false);
  }

  const Canclebtn = () => {
    history.push("/laundry")
  }

  const Backbtn = () => {
    history.push("/all-area")
  }

  useEffect(() => {
    dispatch(onGetArealist());
    dispatch(getLaundries());
  }, [dispatch])

  useEffect(async () => {
    if(window?.google){
      setGoogleLoaded(true);
    }
    reloadMapCenter();

    const AreaResponse = await postArea({});
    setAreaOption(
      AreaResponse?.record?.map((item) => {
        return { label: item?.name, value: item?._id }
      })
    )

    
  }, [])

  const handleOnchange = (val) => {
    setAreaVal(val ? val.split(",") : [])
  }

  const handleDefaultLaundry = (val) => {
    //if val is null, set the default laundry to null
    if(val == null || val?.value == "") {
      setdefaultLaundry("");
      return;
    } else {
     setdefaultLaundry(val);
    }
  }

  const handleDefaultLaundryOld = (val) => {
    if(val == null || val?.value == "") {
      setdefaultLaundryOld("");
      return;
    } else {
      setdefaultLaundryOld(val);
    }
  }

  const handleMaxPercentage = (percentageValue, newUser) => {
    let value = percentageValue ? percentageValue.toString() : ''
    console.log("percentageValue", percentageValue, newUser, value);
    setError((err) => ({ ...err, maxPercentage: "", minPercentage: "", maxPercentageOld: "", minPercentageOld: "" }));
    if (value === null || value === undefined || value === '') {
      if(newUser) setOrderPercentage(''); 
      else setOrderPercentageOld(''); // Set it to an empty string
      return;
    }
    value = value.replace(/[^\d]/g, ''); // Remove non-digit characters
  
    // Allow clearing the input
    if (value === '0' || value === '') {
      if(newUser) {
        setOrderPercentage(''); // Clear the input if empty
        setError((err) => ({ ...err, maxPercentage: "", minPercentage: "Min Percentage should be greater than 0" }));  
      }
      else{
        setOrderPercentageOld(''); // Clear the input if empty
        setError((err) => ({ ...err, maxPercentageOld: "", minPercentageOld: "Min Percentage should be greater than 0" }));
      }
      return;
    }
  
    // Check for valid percentage range
    if (value > 100) {
      if(newUser) setError((err) => ({ ...err, maxPercentage: "Max Percentage should be less than 100" }));
      else setError((err) => ({ ...err, maxPercentageOld: "Max Percentage should be less than 100" }));
      return;
    } else if (value < 1) {
      if(newUser) setError((err) => ({ ...err, minPercentage: "Min Percentage should be greater than 0" }));
      else setError((err) => ({ ...err, minPercentageOld: "Min Percentage should be greater than 0" }));
      return;
    }

    if (value === '1') {
      if(newUser) setOrderPercentage('1');
      else setOrderPercentageOld('1');
      return;
    }

    // Set the order percentage if valid
    if(newUser) setOrderPercentage(value);
    else setOrderPercentageOld(value);
  };
  
  useEffect(() => {
    setSelectedArea({});
    if(createdPolygon){
      createdPolygon.setMap(null);
      setCreatedPolygon(null);
    }
  }, [arealist])

  useEffect(() => {
    let showAll = showInActiveAreas && showActiveAreas; 
    if(!showAll){

      if(!showActiveAreas && !showInActiveAreas){
        setArealist([]);
        return;
      }

      let areaStatus = false;
      if(showActiveAreas){
        areaStatus = true;
      }
      const _areaList = areas?.filter( area => area.status == areaStatus);
      setArealist(_areaList);
    }
    else{
      setArealist(areas);
    }

  }, [areas, showInActiveAreas, showActiveAreas])


  useEffect(() => {
    if (users.length > 0) {
      setUserData(users[0]);
    }
  }, [users])

  useEffect(() => {
    if(showMergeModal){
      setAreaVal([]);
    }
  }, [showMergeModal])

  const discountChecked = (type, value) => {
    const _discount = {...discount};
    if(value != true){
      _discount[type] = 0;
    }
    _discount[ type + "Checked"] = value;

    setDiscount(_discount);
  }



  const coordsSend = (item2) => {
    undoBtn.current.disabled = true;
    editSteps.current = {steps:[], lastStep: null};
    if (allPolygon?.length) {
      let findPolygone = allPolygon.find((data) => data.id == item2._id);
      
      if(findPolygone?.polygon){
        polygonRef.current = findPolygone.polygon;
        polygonRef.current.editable = true;
        
        const _origionalPath = polygonRef.current
          .getPath()
          .getArray()
          .map(latLng => {
            return {lat: latLng.lat(), lng: latLng.lng()};
          });
        
        editSteps.current.lastStep = _origionalPath;
        setOrigionalPath(_origionalPath);
          
      }
    }

    if (arealist?.length) {
      let findAreaOject = arealist.find((data) => data._id == item2._id);
      if(findAreaOject){
        setSelectedArea(findAreaOject)
      }

      if (item2?._id) {
        setPolygonEditable(true);
        setDrawingPolygone(null);
        setAreaName(item2.name);
        setMinOrderAmount(item2.minimum);
        setDelivaryFees(item2.delFee);
        setAreaEdit(true);
        SetShowForm(true);
        setAreaStatus(item2.status);
        setLoyaltyPoints(item2?.loyaltyPoints);
        let _discount = item2?.discount;
        let _showDefaultLaundryAndPercentage = (item2?.defaultLaundry?.percentageOfOrder &&  item2?.defaultLaundry?.laundry) ? true : false
        let _showOldDefaultLaundryAndPercentage = (item2?.defaultLaundryOld?.percentageOfOrder &&  item2?.defaultLaundryOld?.laundry) ? true : false
       
        if(item2?.discount?.online){
          _discount.onlineChecked = true;   
        }
        if (item2?.discount?.offline) {
          _discount.offlineChecked = true;
        }

        setDiscount(_discount);

        const selectedVal = laundryList.filter((item) => item._id == findAreaOject?.defaultLaundry?.laundry);
        const selectedValOld = laundryList.filter((item) => item._id == findAreaOject?.defaultLaundryOld?.laundry);
          if(selectedVal.length > 0){
            setdefaultLaundry({label: selectedVal[0]?.nameEn, value: selectedVal[0]?._id});
          }else{
            setdefaultLaundry("");
          }

          if(selectedValOld.length > 0){
            setdefaultLaundryOld({label: selectedValOld[0]?.nameEn, value: selectedValOld[0]?._id});
          }
          else{
            setdefaultLaundryOld("");
          }
          
        setOrderPercentage(item2?.defaultLaundry?.percentageOfOrder);
        setOrderPercentageOld(item2?.defaultLaundryOld?.percentageOfOrder);
        setShowDefaultLaundryAndPercentage(_showDefaultLaundryAndPercentage)
        setShowOldDefaultLaundryAndPercentage(_showOldDefaultLaundryAndPercentage)
      
        
        
        setError({});
      }


      
      if(findAreaOject){
        
        setAllCoords(findAreaOject?.location?.coordinates[0])
      }
    }
  }

  useEffect(() => {
    

    if (allPolygon?.length) {
      let findPolygone = allPolygon.find((data) => data.id == selectedArea._id);
      
      if(findPolygone?.polygon){
        polygonRef.current = findPolygone.polygon;
        const path = polygonRef.current.getPath();
        listenersRef.current.push(
          google.maps.event.addListener(path, "set_at", onEdit),
          google.maps.event.addListener(path, "insert_at", onEdit),
          google.maps.event.addListener(path, "remove_at", onEdit),
        );
      }
    }
  }, [allCoords])


  const onPolygonCreated = (polygon) => {
    reloadMapCenter();
    if(!showForm){
      polygon.setPath([]);
      polygon.setMap(null);
    }

    if(polygon){
      setCreatedPolygon(polygon);
      polygonRef.current = polygon;
    }
    setDrawingPolygone(null)
    
  };

  const onOverlayComplete = (polygon) => {
   
  }


  // Bind refs to current Polygon and listeners
  const onLoad = (polygon, id) => {
    allPolygon.push({ polygon, id });
    setAllPolygon(allPolygon);
    const path = polygon.getPath();
  };


  //AddNewArea
  const AddNewArea = (e) => {
    e.preventDefault();
    setError({});
    let valid = true;
    if (!areaName) {
      setError((err) => {return {...err,  Area: "error"}});
      valid = false;
    }

    
    if (minOrderAmount < 0 || minOrderAmount === "") {
      setError((err) => { return {...err,  minOrderAmount: "error"}});
      valid = false;
    }
    
    if (delivaryFees < 0 || delivaryFees === ""){
      setError((err) => { return {...err,  delivaryFees: "error"}});
      valid = false;
    } 
    
    if (!areaEdit && !createdPolygon?.getPath) {
      setError((err) => { return {...err,  Polygon: "error"}});
      valid = false;
    }

    if(discount && discount?.onlineChecked && myParseFloat(discount?.online) ==0 ){
      setError((err) => { return {...err,  onlineDiscount: "error"}})
      valid = false;
    }

    if(discount && discount?.offlineChecked && myParseFloat(discount?.offline) ==0 ){
      setError((err) => { return {...err,  offlineDiscount: "error"}})
      valid = false;
    }

    if(showDefaultLaundryAndPercentage){
      //checking validity for order percentage
      if(orderPercentage < 1 || orderPercentage > 100 || orderPercentage==null){
        setError((err) => { return {...err,  orderPercentage: "error"}})
        valid = false;
      }

      if(defaultLaundry==null || defaultLaundry?.value=="" || defaultLaundry=="" ){
        setError((err) => { return {...err,  defaultLaundry: "error"}});
        valid = false;
      }
    }
   
    if(showOldDefaultLaundryAndPercentage){
      if(orderPercentageOld < 1 || orderPercentageOld > 100 || orderPercentageOld==null){
        setError((err) => { return {...err,  orderPercentageOld: "error"}})
        valid = false;
      }

      if(defaultLaundryOld==null || defaultLaundryOld?.value=="" || defaultLaundryOld=="" ){
        setError((err) => { return {...err,  defaultLaundryOld: "error"}});
        valid = false;
      }

    }
   

    if(!valid){
      return;
    }

    let nextPath =[];
    if(createdPolygon && !areaEdit){
      const coordinates = createdPolygon.getPath()
        .getArray();

        if(coordinates.length == 0){
          setError((err) => { return {...err,  Polygon: "error"}});
          return false;
        }
      
      
      for(let i=0; i<coordinates.length; i++){
        nextPath.push([coordinates[i].lng(), coordinates[i].lat()]);
      }
        
      nextPath.push([coordinates[0].lng(), coordinates[0].lat()]);
      setCheckPolygon(nextPath);
    }

    if (areaEdit && polygonRef.current) {
      
      const coordinates = polygonRef.current.getPath()
      .getArray();
      
      for(let i=0; i<coordinates.length; i++){
        nextPath.push([coordinates[i].lng(), coordinates[i].lat()]);
      }
      
      nextPath.push([coordinates[0].lng(), coordinates[0].lat()]);
      
    }

    
    // UpdateArea(nextPath)
    let data = {
      name: areaName,
      delFee: parseInt(delivaryFees),
      status: areaStatus,
      minimum: parseFloat(minOrderAmount),
      location: {
        type: "Polygon",
        coordinates: [nextPath]
      },
      loyaltyPoints: loyaltyPoints,
      discount : {online : discount?.online, offline : discount?.offline},
    }

    if(showDefaultLaundryAndPercentage || showOldDefaultLaundryAndPercentage){
      if(showDefaultLaundryAndPercentage){
        data = {
          ...data,
          defaultLaundry: {
            laundry: defaultLaundry?.value,
            percentageOfOrder: parseInt(orderPercentage)
          },
          defaultLaundryOld: showOldDefaultLaundryAndPercentage ? {
            laundry: defaultLaundryOld?.value,
            percentageOfOrder: parseInt(orderPercentageOld)
          } : {}
        }
      }
      if(showOldDefaultLaundryAndPercentage){
        data = {
          ...data,
          defaultLaundry: showDefaultLaundryAndPercentage ? {
            laundry: defaultLaundry?.value,
            percentageOfOrder: parseInt(orderPercentage)
          } : {},
          defaultLaundryOld:  {
            laundry: defaultLaundryOld?.value,
            percentageOfOrder: parseInt(orderPercentageOld)
          }
        }
      } 
    }else{
      setdefaultLaundry("");
      setOrderPercentage(null);
      setdefaultLaundryOld("");
      setOrderPercentageOld(null);
      data = {
        ...data,
        discount : {online : discount?.online, offline : discount?.offline},
        defaultLaundry: {},
        defaultLaundryOld: {}
      }
    }
    

    //setAllCoords(nextPath[0])
    
    if(areaEdit){
      data._id = selectedArea._id;
      
      if(polygonRef.current)
        polygonRef.current.editable = false;
      
      postWeb(url.UPDATE_AREA_LIST, data).then(() => {
        dispatch(onGetArealist());
        toastr.success(`Area Updated Succesfully.`, `Success`);
      });
    }
    else{
      createdPolygon.editable = false;
      postWeb(url.ADD_NEW_AREA, data).then(() => {
        dispatch(onGetArealist());
        toastr.success(`Area Created Succesfully.`, `Success`);

      })
      
    }
    SetShowForm(false);
    
    //window.location.reload(false);
  }

  const reloadMapCenter = () =>{
   
    if(mapRef && mapRef.current){
      const newPos = mapRef.current.getCenter().toJSON();

      if(newPos){
        setAllCoords([[newPos.lng, newPos.lat]]);
      }
    }
    else{
      let latlongArray = [];
      latlongArray.push([50.21140906959773, 26.286242763093988]);
      setAllCoords(latlongArray); 
    }
  }


  const AddAreabtn = () => {
    if (!mapRef.current) return;
    
    reloadMapCenter();
    polygonRef.current = null;

    setSelectedArea({});
    setDrawingPolygone("polygon")
    
    // setFormDivClass("col-md-4");
    // setMainDivClass("col-md-8");
    SetShowForm(true);
    setAreaName("");
    setMinOrderAmount("");
    setDelivaryFees("");
    setAreaEdit(false);
    setAreaStatus(true);
    setLoyaltyPoints(defaultLoyaltyPoints);
    setDiscount({online : "", offline:""})
    setShowDefaultLaundryAndPercentage(true);
    setShowOldDefaultLaundryAndPercentage(true)
    setOrderPercentage("");
    setdefaultLaundry("");
    setOrderPercentageOld("");
    setdefaultLaundryOld("");
  }

  
  const CancleAddNewArea = (e) => {
    e.preventDefault();
    setSelectedArea({});
    setDrawingPolygone(null)
    SetShowForm(false);
    if(!areaEdit ){
      if(polygonRef.current)
        polygonRef.current.setMap(null);
    }
    else{
      if(polygonRef.current){
        const arrLatLng = polygonRef.current
          .getPath()
          .getArray();
        
        const latLang = arrLatLng?.[0];
        if(latLang){
          setAllCoords([[latLang.lng(), latLang.lat()]]);
        }
      }
    }
  }

  const onMapLoad = (map) =>{
    mapRef.current = map;
  }

  // Clean up refs
  const onUnmount = useCallback(() => {
    listenersRef.current.forEach(lis => lis.remove());
    polygonRef.current = null;
  }, []);

  const renderPloygone = (item) => {
    let polygonPath = item?.location?.coordinates[0] && item?.location?.coordinates[0]?.map((item) => {
      return { lat: item[1], lng: item[0] }
    });
    
    


    if(item._id == selectedArea._id){
      if (polygonRef.current) {
        const nextPath = polygonRef.current
          .getPath()
          .getArray()
          .map(latLng => {
            return {lat: latLng.lat(), lng: latLng.lng()};
          });
          polygonPath = nextPath;
          
          
      }
       
    }

    const editable = item._id == selectedArea._id;

    return <Polygon
      // Make the Polygon editable / draggable
      editable={editable}
      draggable={false}
      path={polygonPath}
      clickable={true}
      onLoad={(e) => onLoad(e, item._id)}
      onUnmount={onUnmount}
      options={{
        fillColor: editable?"#82E0AA":"#85C1E9",
        strokeColor:"#3498DB",
        fillOpacity: 1,
        strokeWeight: 2,
        clickable: true,
        zIndex: 1
      }}
      id={item._id}
    />
  }

  const onMapPositionChanged = (a,b) => {
    
  }

  const validateNumber = (e) => {
    let _value = myParseFloat(e.target.value);
    
    if(e.target.value.trim() === ""){
      setError((err) => {return {...err, [e.target.name]: "error"}} )
      _value = "";
    }
    else if(_value >=0){
    
      setError((err) => {return {...err, [e.target.name]: false}} )
    }
    else if(_value < 0){
      return;
    }
    
    if(e.target.name == "minOrderAmount")
      setMinOrderAmount(_value);
    else if(e.target.name == "delivaryFees")
      setDelivaryFees(_value);
    else if(e.target.name == "earnPoints")
      setLoyaltyPoints({...loyaltyPoints, earnPoints: _value});
    else if(e.target.name == "burnPoints")
      setLoyaltyPoints({...loyaltyPoints, burnPoints: _value});
    else if(e.target.name == "onlineDiscount")
      setDiscount({...discount, online: _value});
    else if(e.target.name == "offlineDiscount")
      setDiscount({...discount, offline: _value});
  }

  const setPackageType = (e, i) => {
    const redeemPackages = loyaltyPoints?.redeemPackages;
    if(redeemPackages && redeemPackages[i]){
      redeemPackages[i].packageType = e.target.value;
      setLoyaltyPoints({...loyaltyPoints, redeemPackages: redeemPackages});
    }
  }

  const validatePkgNumbers = (e, i) => {
    let _value = myParseFloat(e.target.value);
    
    if(e.target.value.trim() === ""){
      setError((err) => {return {...err, [e.target.name]: "error"}} )
      _value = "";
    }
    else if(_value >=0){
    
      setError((err) => {return {...err, [e.target.name]: false}} )
    }
    else if(_value < 0){
      return;
    }
    const redeemPackages = loyaltyPoints?.redeemPackages;
    if(e.target.name == "val"){
      redeemPackages[i].value = _value;
    }
    else if(e.target.name=="packagePoints"){
      redeemPackages[i].points = _value;
    }
    else if(e.target.name=="packageType"){
      redeemPackages[i].type = _value;
    } 
    

    setLoyaltyPoints({...loyaltyPoints, redeemPackages: redeemPackages});
      
  }

  const validareAreaName = (value) => {
    if(value.trim() === ""){
      setError((err) => {
        return {...err, Area: "error"}
      })
    }
    else{
      setError((err) => {
        return {...err, Area: false}
      })
    }
    setAreaName(value);
  }

  const addPackageRow = () => {
    setLoyaltyPoints({...loyaltyPoints, redeemPackages: [...loyaltyPoints?.redeemPackages, {
      points: 0,
      value: 0,
      packageType: "SAR"
    }]});

  }

  const removePackage = (i) => {
    const redeemPackages = loyaltyPoints?.redeemPackages;
    redeemPackages.splice(i,1);
    setLoyaltyPoints({...loyaltyPoints, redeemPackages: redeemPackages});
  }

  const reOrganizeArea = async () => {
    if (areaVal?.length < 1) {
      setError({ area: "Area Required" });
      return
    }
    
    setError({});
    setReorgProcessing(true);

    const response = await postOrganizeArea(areaVal);
    if(response.statusCode == 200){
      if(response.missingAreas == true){
        if(response.path){
          toastr.success(`Area re-organization completed. Area missing for some areas, check the exported CSV`, `Success`);
        
          const url = API_BASE_URL  + "/" + response.path;
          axios.get(url, {
            responseType: 'blob',
          })
          .then((res) => {
            fileDownload(res.data, "areas.csv")
          })

        }
       
      }
      else{
        
        toastr.success(`Area re-organization completed. No missing areas`, `Success`);
      }
    }
    else{
      toastr.error(`Error in area re-oragnization.`, `Error`);
    }
    setReorgProcessing(false);
    setShowMergeModal(false);
  };


  const undoDrawing = e => {
    e.preventDefault();
    

    if(editSteps?.current?.steps?.length > 0){
      const path = editSteps.current.steps.pop();
      
      
      //polygonRef.current.setMap(null);
      polygonRef.current.setPath(path);
      if(editSteps.current.steps.length <=0){
        undoBtn.current.disabled = true;
      }

      const newPath = polygonRef.current.getPath();
      listenersRef.current.push(
        google.maps.event.addListener(newPath, "set_at", onEdit),
        google.maps.event.addListener(newPath, "insert_at", onEdit),
        google.maps.event.addListener(newPath, "remove_at", onEdit),
      );
      
      //polygonRef.current.setMap(mapRef.current);
      // polygonRef.current.editable = false;
      // polygonRef.current.setMap(null);
      // setTimeout(() => {
      //   polygonRef.current.setPath(path);
      //   polygonRef.current.setMap(mapRef.current);
      //   console.log("polygonRef.current After::", polygonRef.current);
      //   polygonRef.current.editable = true;
      // }, 100); 

      
      editSteps.current.lastStep = path;
    }
  }

  const toggle = () => {
    setShowTerms(!showTerms);
  };

  const toggleMergeModal = () => {
    setShowMergeModal(!showMergeModal);
  };

  const saveEditorData = () => {
    const data = termsEditor.current.getData();
    setLoyaltyPoints({...loyaltyPoints, terms: data});
    setShowTerms(false);
  }

  const showTermsModal = () => {
    setShowTerms(true);
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>AREA | Kleen Admin Panel</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={"Home"}
            breadcrumbItem={"Area Management"}
          />
          <div className="dashboard-cards-section">
            <div className="container">
              <div className="d-flex justify-content-end">
              <Label>
                Active Areas : 
              </Label>
              <div className="form-check form-switch ps-2  me-3">
                <Switch
                  height={25}
                  width={70}
                  uncheckedIcon={<HideSymbol />}
                  checkedIcon={<ShowSymbol />}
                  className="me-1 mb-sm-8 mb-2"
                  onColor="#626ed4"
                  onChange={(e) => setShowActiveAreas(!showActiveAreas)}
                  checked={showActiveAreas}
                />
              </div>
              <Label>
                InActive Areas : 
              </Label>
              <div className="form-check form-switch ps-2 me-5">
                <Switch
                  height={25}
                  width={70}
                  uncheckedIcon={<HideSymbol />}
                  checkedIcon={<ShowSymbol />}
                  className="me-1 mb-sm-8 mb-2"
                  onColor="#626ed4"
                  onChange={(e) => setShowInActiveAreas(!showInActiveAreas)}
                  checked={showInActiveAreas}
                />
              </div>
              {(userData?.permissions?.allPermissions == true || userData?.permissions?.area?.create == true) ?
                
                <Button
                  className="update-area-map2 mx-2 btn-rounded mb-2 me-2 btn btn-success"
                  color="success"
                  onClick={() => AddAreabtn()}> 
                  <i className="mdi mdi-plus me-1"></i>Add Area
                </Button>
               : null}

              {(userData?.permissions?.allPermissions == true || userData?.permissions?.areaReOrganize?.create == true || userData?.permissions?.areaReOrganize?.update == true) ?
                <Button
                className="update-area-map2 mx-2 btn-rounded mb-2 me-2 btn btn-warning"
                color="success"
                onClick={() => setShowMergeModal(true)}> 
                Re-Organize
                </Button>
                : null}
                {/* <Button
                    className="update-area-map1"
                    color="primary"
                    onClick={() => onEdit()}>Update Area</Button> */}

              </div>
              <div className="row mt-4">
                <div className={showForm?"col-md-7":"col-md-12"}>
                  
                    {
                      allCoords?.length  && googleLoaded? (
                        <GoogleMap
                          onLoad={onMapLoad}
                          mapContainerClassName="App-map custome-map"
                          center={{
                            lat: allCoords[0][1], lng: allCoords[0][0]
                          }}
                          zoom={11}
                          id="map"
                          onContentChanged={onMapPositionChanged}
                        >
                          <DrawingManager
                            drawingMode={drawingPolygone}
                            onPolygonComplete={value => onPolygonCreated(value)}
                            onOverlayComplete={onOverlayComplete}
                            options={{
                              polygonOptions:{
                              fillColor: "#82E0AA",
                              strokeColor:"#3498DB",
                              fillOpacity: 1,
                              strokeWeight: 2,
                              clickable: false,
                              editable: true,
                              zIndex: 1,
                            }
                          }}
                            
                          />
                          {
                            arealist.map(renderPloygone)
                          }
                        </GoogleMap>
                      ) : null
                    }
                  
                </div>
                <div className={showForm?"col-md-5":"d-none"}>
                  <Card>
                    <CardBody className="p-3">
                    <CardTitle className="mb-2">{areaEdit?'Edit Area':'Add New Area'}</CardTitle>
                    <Form>
                      <div className="mb-3">
                        <Label>Area Name</Label>
                        <Input
                          type="text"
                          className={"form-control " + (error?.Area ?"is-invalid":"")}
                          id="horizontal-firstname-Input"
                          placeholder="Enter area name"
                          value={areaName}
                          onChange={(e) => { validareAreaName(e.target.value) }}
                        />
                    </div>
                    {error?.Area ? <p className="error_valid">* Enter Area Name</p> : null}


                    <div className="mb-3">
                        <Label>Minimum Order Amount</Label>
                        <Input
                          type="number"
                          className={"form-control " + (error?.minOrderAmount?"is-invalid":"")}
                          placeholder="Minimum order amount"
                          
                          value={minOrderAmount}
                          name="minOrderAmount"
                          onChange={(e) => { validateNumber(e) }}
                          onWheel={(e) => e.target.blur()}
                        />
                        {error?.minOrderAmount=="error" ? <p className="error_valid">* Please enter minimum order amount</p> : null}

                    </div>
                      <div className="mb-3">
                          <Label>Delivery Fees</Label>
                          <Input
                            type="number"
                            className={"form-control " + (error?.delivaryFees?"is-invalid":"")}
                            placeholder="Enter delivery fees"
                            value={delivaryFees}
                            name="delivaryFees"
                            onChange={(e) => { validateNumber(e) }}
                            onWheel={(e) => e.target.blur()}
                          />
                      {error?.delivaryFees ? <p className="error_valid">* Enter Delivary Fees</p> : null}

                      </div>
                    
                      <div className="mb-3 d-flex align-items-center">
                        <Label>Status</Label>
                        <div className="form-check form-switch">
                          <Switch
                            uncheckedIcon={<Offsymbol />}
                            checkedIcon={<OnSymbol />}
                            className="me-1 mb-sm-8 mb-2"
                            onColor="#626ed4"
                            onChange={(e) => setAreaStatus(!areaStatus)}
                            checked={areaStatus}
                          />
                        </div>
                    </div>
                    {/* Added by me  */}
                    <div className="mb-2 d-flex align-items-center">
                        <Label>Set Default Laundry <span className="fw-bolder">(New)</span></Label>
                        <div className="form-check form-switch">
                          <Switch
                            uncheckedIcon={<Offsymbol />}
                            checkedIcon={<OnSymbol />}
                            className="me-1 mb-sm-8 mb-2"
                            onColor="#626ed4"
                            onChange={(e) => setShowDefaultLaundryAndPercentage(prev=>!prev)}
                            checked={showDefaultLaundryAndPercentage}
                          />
                        </div>
                    </div>
                    {showDefaultLaundryAndPercentage &&  (<>
                    {/*Dropdown Default Laundry for new customers */}
                      <div className="mb-2" style={{zIndex: 9999}}>
                        <Row>
                          <Col lg={6} className="d-flex align-items-center">
                            <Label>Default Laundry for <span className="fw-bolder">New Users</span></Label>
                          </Col>
                          <Col lg={6}>
                            <Select
                              options={[
                                { label: "None", value: "" }, // Add "None" option
                                ...laundryList?.map((item) => ({
                                  label: item?.nameEn,
                                  value: item?._id
                                }))
                              ]}
                              placeholder="Select Default Laundry"
                              value={defaultLaundry}
                              name="defaultLaundry"
                              styles={{
                                menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Ensure dropdown is on top
                              }}
                              menuPortalTarget={document.body}
                              onChange={(e) => handleDefaultLaundry(e)}
                            />
                        </Col>
                        </Row>
                        {error?.defaultLaundry ? <p className="error_valid">* Default Laundry cannot be empty</p> : null}
                      </div>

                      {/*Input of "percentage of orders" to Default Laundry for new customers */}
                      <div className="mb-2" style={{zIndex: 10}}>
                        <Row>
                          <Col lg={6} className="d-flex align-items-center">
                            <Label>Percentage of Orders</Label>
                          </Col>
                          <Col lg={6}>
                            <InputGroup>
                              <Input
                                type="text"
                                className={"form-control"}
                                placeholder="Percentage of Orders"
                                value={orderPercentage || ''}
                                max={100}
                                valid={orderPercentage > 0 && orderPercentage <= 100}
                                name="orderPercentage"
                                onChange={(e)=>handleMaxPercentage(e.target.value, true)}
                                onWheel={(e) => e.target.blur()}
                                required
                              />
                              <InputGroupText>%</InputGroupText>
                              {error?.maxPercentage ? <p className="error_valid">* Max percentage allowed is 100</p> : null}
                              {error?.minPercentage ? <p className="error_valid">* Min percentage allowed is 1</p> : null}
                              {error?.orderPercentage ? <p className="error_valid">* Enter Percentage of Orders</p> : null}
                            </InputGroup>
                          </Col>  
                        </Row>
                      </div>
                    </>)} 

                    <div className="mb-2 d-flex align-items-center">
                        <Label>Set Default Laundry <span className="fw-bolder">(Old)</span></Label>
                        <div className="form-check form-switch">
                          <Switch
                            uncheckedIcon={<Offsymbol />}
                            checkedIcon={<OnSymbol />}
                            className="me-1 mb-sm-8 mb-2"
                            onColor="#626ed4"
                            onChange={(e) => setShowOldDefaultLaundryAndPercentage(prev=>!prev)}
                            checked={showOldDefaultLaundryAndPercentage}
                          />
                        </div>
                    </div>

                    {showOldDefaultLaundryAndPercentage && (
                      <>
                      {/*Dropdown Default Laundry for old customers */}
                        <div className="mb-2" style={{zIndex: 9999}}>
                          <Row>
                            <Col lg={6} className="d-flex align-items-center">
                              <Label>Default Laundry for <span className="fw-bolder">Old Users</span></Label>
                            </Col>
                            <Col lg={6}>
                              <Select
                                options={[
                                  { label: "None", value: "" }, // Add "None" option
                                  ...laundryList?.map((item) => ({
                                    label: item?.nameEn,
                                    value: item?._id
                                  }))
                                ]}
                                placeholder="Select Default Laundry"
                                value={defaultLaundryOld}
                                name="defaultLaundryOld"
                                styles={{
                                  menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Ensure dropdown is on top
                                }}
                                menuPortalTarget={document.body}
                                onChange={(e) => handleDefaultLaundryOld(e)}
                              />
                          </Col>
                          </Row>
                          {error?.defaultLaundryOld ? <p className="error_valid">* Default Laundry cannot be empty</p> : null}
                        </div>
                        {/*Input of "percentage of orders" to Default Laundry for old customers */}
                        <div className="mb-2" style={{zIndex: 10}}>
                          <Row>
                            <Col lg={6} className="d-flex align-items-center">
                              <Label>Percentage of Orders</Label>
                            </Col>
                            <Col lg={6}>
                              <InputGroup>
                                <Input
                                  type="text"
                                  className={"form-control"}
                                  placeholder="Percentage of Orders"
                                  value={orderPercentageOld || ''}
                                  max={100}
                                  valid={orderPercentageOld > 0 && orderPercentageOld <= 100}
                                  name="orderPercentageOld"
                                  onChange={(e)=>handleMaxPercentage(e.target.value, false)}
                                  onWheel={(e) => e.target.blur()}
                                  required
                                />
                                <InputGroupText>%</InputGroupText>
                                {error?.maxPercentageOld ? <p className="error_valid">* Max percentage allowed is 100</p> : null}
                                {error?.minPercentageOld ? <p className="error_valid">* Min percentage allowed is 1</p> : null}
                                {error?.orderPercentageOld ? <p className="error_valid">* Enter Percentage of Orders</p> : null}
                              </InputGroup>
                            </Col>
                          </Row>
                        </div>
                      </>
                    )}

                    <h5 className="mb-3"> <i className="bx bxs-star"></i> Loyalty Points <br /><span className="ms-2 text-danger font-size-10">(This feature is deprecated)</span></h5>

                    <div className="mb-1 d-flex align-items-center">
                        <Label>Online</Label>
                        <div className="form-check form-switch">
                          <Switch
                            height={25}
                            uncheckedIcon={<Offsymbol />}
                            checkedIcon={<OnSymbol />}
                            className="me-1 mb-sm-8 mb-2"
                            onColor="#626ed4"
                            onChange={(e) => setLoyaltyPoints({...loyaltyPoints, online : !loyaltyPoints?.online})}
                            checked={loyaltyPoints?.online}
                          />
                        </div>
                    </div>

                    <div className="mb-1 mt-1 d-flex align-items-center">
                        <Label>Offline</Label>
                        <div className="form-check form-switch">
                          <Switch
                            height={25}
                            uncheckedIcon={<Offsymbol />}
                            checkedIcon={<OnSymbol />}
                            className="me-1 mb-sm-8 mb-2"
                            onColor="#626ed4"
                            onChange={(e) => setLoyaltyPoints({...loyaltyPoints, offline : !loyaltyPoints?.offline})}
                            checked={loyaltyPoints?.offline}
                          />
                        </div>
                    </div>

                    {(loyaltyPoints?.online || loyaltyPoints?.offline)?
                    <div className="mb-2">
                      <Row>
                        <Col lg={6} className="d-flex align-items-center">
                          <Label className="m-0">Points for 1 SAR spent </Label>
                        </Col>
                        <Col lg={6}>
                          <InputGroup>
                            <Input
                              type="number"
                              className={"form-control " + (error?.earnPoints?"is-invalid":"")}
                              placeholder="Points for every riyal spent"
                              value={loyaltyPoints?.earnPoints}
                              name="earnPoints"
                              onChange={(e) => { validateNumber(e) }}
                              onWheel={(e) => e.target.blur()}
                            />
                              <InputGroupText>Points</InputGroupText>
                            </InputGroup>
                        </Col>
                      </Row>
                      {error?.earnPoints ? <p className="error_valid">* Enter earn points</p> : null}
                    </div>: null}

                    {(loyaltyPoints?.online || loyaltyPoints?.offline)?  
                    <div>
                    <Row>
                      <Col className="d-flex align-items-center">
                        <Label className="m-0">Burn 1 point for </Label>
                      </Col>
                      <Col>
                      <InputGroup>
                      <Input
                        type="number"
                        className={"form-control " + (error?.burnPoints?"is-invalid":"")}
                        placeholder="Burn 1 point for"
                        value={loyaltyPoints?.burnPoints}
                        name="burnPoints"
                        onChange={(e) => { validateNumber(e) }}
                        onWheel={(e) => e.target.blur()}
                      />
                      <InputGroupText>SAR</InputGroupText>
                      </InputGroup>
                      </Col>
                    </Row>
                      {error?.burnPoints ? <p className="error_valid">* Enter Burn Points Formula</p> : null}
                    </div>: null}

                    <h6 className="fw-bolder fs-6 mt-3">Terms</h6>
                    <div className="mb-4">
                      <Row>
                        <Col className="d-flex align-items-center">
                          <Link to="#" onClick={() => showTermsModal()} > <i className="mdi mdi-lead-pencil me-1"></i> View / Update Terms</Link>
                        </Col>
                      </Row>
                    </div>
                    {false?
                    <>
                    <h6 className="fw-bolder fs-6">Redeem Packages</h6>
                    <div className="mb-3">
                      <Row>
                        <Col className="d-flex align-items-center">
                          <h6 className="fw-bold">Points</h6>
                        </Col>
                        <Col className="d-flex align-items-center">
                          <h6 className="fw-bold">Redeem Type</h6>
                        </Col>
                        <Col className="d-flex align-items-center">
                          <h6 className="fw-bold">Package</h6>
                        </Col>
                        <Col md={1} className="d-flex align-items-center">
                          &nbsp;
                        </Col>
                      </Row>
                      
                      {loyaltyPoints?.redeemPackages && loyaltyPoints?.redeemPackages.map((pkg, i) => (
                        <Row key={i} className="mb-2">
                          <Col>
                            <Input
                                type="number"
                                className={"form-control " + (error?.packagePoints?"is-invalid":"")}
                                placeholder="Points"
                                value={pkg.points}
                                name="packagePoints"
                                onChange={(e) => { validatePkgNumbers(e, i) }}
                                onWheel={(e) => e.target.blur()}
                              />
                          </Col>
                          <Col>
                            <select 
                              className="form form-control"
                              name="packageType"
                              onChange={(e) => setPackageType(e, i)}
                              value={pkg.packageType}
                              >
                              <option value="">Select Type</option>
                              <option value="SAR">SAR</option>
                              <option value="CARWASH">CARWASH</option>
                            </select>
                          </Col>
                          <Col>
                            <InputGroup>
                              <Input
                                type="number"
                                className={"form-control " + (packageErrors?.[i]?.val?"is-invalid":"")}
                                placeholder="Burn 1 point for"
                                value={loyaltyPoints?.redeemPackages?.[i]?.value}
                                name="val"
                                onChange={(e) => { validatePkgNumbers(e, i) }}
                              />
                            <InputGroupText>SAR</InputGroupText>
                            </InputGroup>
                          </Col>
                          <Col md={1} className="d-flex align-items-center">
                            <Link
                                to="#"
                                onClick={() => removePackage(i)}
                                className="action-icon text-danger text-center"
                              >
                                {" "}
                                <i className="mdi mdi-trash-can font-size-18" />
                              </Link>
                          </Col>
                        </Row>
                      ))}                      
                    </div>
                    <div className="d-flex justify-content-end mb-4">
                        <Button 
                          className="btn btn-sm btn-success"
                          onClick={addPackageRow}
                          >
                            <i className="mdi mdi-plus me-1"></i> Add Package
                        </Button>
                    </div>
                    </>:null}
                    

                    <h5 className="mb-3"> <i className="bx bx-money"></i> Discount </h5>
                    
                    <Row className="mb-1 ">
                      <Col>
                        <div  className="d-flex align-items-center">
                          <Label>Online</Label>
                          <div className="form-check form-switch">
                            <Switch
                              height={25}
                              uncheckedIcon={<Offsymbol />}
                              checkedIcon={<OnSymbol />}
                              className="me-1 mb-sm-8 mb-2"
                              onColor="#626ed4"
                              onChange={(e) => discountChecked('online', !discount?.onlineChecked) }
                              checked={discount?.onlineChecked}
                            />
                          </div>
                        
                          {discount?.onlineChecked?
                          <div className="ms-4">
                          <InputGroup >
                            <Input
                              type="number"
                              className={"form-control " + (error?.onlineDiscount?"is-invalid":"")}
                              placeholder="Online discount %"
                              value={discount?.online}
                              name="onlineDiscount"
                              onChange={(e) => { validateNumber(e) }}
                              onWheel={(e) => e.target.blur()}
                            />
                            <InputGroupText>%</InputGroupText>
                          </InputGroup>
                          <br />
                          {error?.onlineDiscount ? <p className="error_valid">* Enter online discount</p> : null}
                          </div>
                          :null}
                        </div>
                      </Col>
                    </Row>

                    <Row className="mb-1 ">
                      <Col>
                        <div  className="d-flex align-items-center">
                          <Label>Offline</Label>
                          <div className="form-check form-switch">
                            <Switch
                              height={25}
                              uncheckedIcon={<Offsymbol />}
                              checkedIcon={<OnSymbol />}
                              className="me-1 mb-sm-8 mb-2"
                              onColor="#626ed4"
                              onChange={(e) => discountChecked('offline', !discount?.offlineChecked)}
                              checked={discount?.offlineChecked}
                            />
                          </div>
                        
                          {discount?.offlineChecked?
                          <div className="ms-4">
                          <InputGroup>
                            <Input
                              type="number"
                              className={"form-control " + (error?.offlineDiscount?"is-invalid":"")}
                              placeholder="Offline discount %"
                              value={discount?.offline}
                              name="offlineDiscount"
                              onChange={(e) => { validateNumber(e) }}
                              onWheel={(e) => e.target.blur()}
                            />
                            <InputGroupText>%</InputGroupText>
                          </InputGroup>
                          {error?.offlineDiscount ? <p className="error_valid">* Enter offline discount</p> : null}
                          </div>
                          :null}
                        </div>

                      </Col>
                    </Row>


                      
                      {error?.Polygon ? <p className="error_valid">* Create Polygon </p> : null}
                      <div className="text-end">
                        <button ref={undoBtn} className="btn btn-secondary mt-2  mx-2" onClick={(e) => { undoDrawing(e) }}>Undo</button>
                        <button className="btn btn-secondary mt-2  mx-2" onClick={(e) => { CancleAddNewArea(e) }}>Close</button>
                        {(userData?.permissions?.allPermissions == true || (userData?.permissions?.area?.update == true && areaEdit)  || (userData?.permissions?.area?.create == true && !areaEdit)) ?
                        <button className="btn btn-primary mt-2" onClick={(e) => { AddNewArea(e) }}>{areaEdit?'Update':'Save'}
                        </button>: null}
                      </div>


                    </Form>
                    </CardBody>
                  </Card>
                  
                </div>
              </div>
            </div>
          </div>
          <ul className="row main_ul">
            {
              arealist?.map((item) => {
                return (
                  <div className="col-md-2" key={item?._id}>
                    <li className={"mapvector_arealist btn btn-outline-primary d-flex mt-2 " + ((selectedArea._id == item._id)?' active':'')} onClick={() => { coordsSend(item) }} >{item?.name}</li>
                  </div>
                )
              })
            }
          </ul>

          <Modal isOpen={showTerms} toggle={toggle} size="lg">
              <ModalHeader toggle={toggle} tag="h4">
                {"Terms"}
              </ModalHeader>
              <ModalBody>
                  <Row className="d-flex">
                  <CKEditor
                      editor={ClassicEditor}
                      config={{
                        toolbar: [ 'bold', 'italic','link', '|', 
                        'heading', 'bulletedList', 'numberedList', 'undo', 'redo' ]
                      }}
                      data={""}
                      onReady={editor => {
                        termsEditor.current = editor;
                        if(loyaltyPoints?.terms)
                          editor.setData(loyaltyPoints?.terms)
                      }}
                      onChange={(event, editor) => {
                        
                        
                      }}
                      
                    />
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <button
                          type="submit"
                          onClick={() => saveEditorData()}
                          className="btn btn-success save-user"
                        >
                          Save
                        </button>
                      </div>
                    </Col>
                  </Row>
                
              </ModalBody>
            </Modal>

            <Modal isOpen={showMergeModal} toggle={toggleMergeModal} size="lg">
              <ModalHeader toggle={toggleMergeModal} tag="h4">
                {"Re-organize Areas"}
              </ModalHeader>
              <ModalBody>
                  <Row className="d-flex">
                  <Col sm={12}>
                      <Label
                        htmlFor="horizontal-password-Input"
                        className="col-sm-3 col-form-label"
                      >
                        Select Areas to Organize <span className="mandatory_feild">*</span>
                      </Label>
                      <MultiSelect
                        defaultValue={areaVal.toString()}
                        className="multi-selects form-selects2 form-controlss areas-box"
                        onChange={handleOnchange}
                        options={areaOption}
                      />
                      {
                        error?.area ? (
                          <label style={{ color: "red" }}>{error?.area}</label>
                        ) : null
                      }
                    </Col>                      
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <div className="text-end">
                        {reorgProcessing?
                          <button
                          type="button"
                          disabled
                          className="btn btn-success save-user"
                        >
                         <i className="fas fa-circle-notch fa-spin"></i>  Processing...
                        </button>
                        :
                        <button
                          type="submit"
                          onClick={() => reOrganizeArea()}
                          className="btn btn-success save-user"
                        >
                          Organize
                        </button>
                        }
                      </div>
                    </Col>
                  </Row>
                
              </ModalBody>
            </Modal>


        </Container>
      </div>
    </React.Fragment>
  );
}



export default (GoogleApiWrapper({
  apiKey: "AIzaSyAvZvBwMKrllZNCXdikzvCDOzu8rwqv-0c",
  libraries: mapLibraries
})(MapsArea));

