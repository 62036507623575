import { postWeb } from 'helpers/api_helper';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import DataTable from 'react-data-table-component';
import { withTranslation } from 'react-i18next';
import { MetaTags } from 'react-meta-tags';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Tooltip, UncontrolledTooltip } from 'reactstrap';
import toastr from 'toastr';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { getLaundrylist, getLaundryWorkers } from 'store/actions';
import PagesNoPermission from 'pages/Utility/pages-no-permission';
import deqaImg from "../../assets/images/brands/deqaImg.png";

const phCode = process.env.REACT_APP_PHONE_PREFIX || 966;
const deqaUrl = process.env.REACT_APP_DEQA_URL;

const LaundryWorkers = (props) => {
  const SectionTitle = ({ title }) => (
    <>
      <h5 className="mt-4 text-primary">{title}</h5>
      <hr />
    </>
  )
  
  SectionTitle.propTypes = {
    title: PropTypes.string,
  }

  const initailFormData = {
    name: '',
    mobile: '',
    email: '',
    address: '',
    healthCertificate: null,
    laundry: '',
    status: false,
    faceImage: null,
  }
  const initailStateOfErrors = {
    mobile: '',
  }
  const { users, laundrylist, workersList, totalWorkersCount } = useSelector(state => {
    console.log('state', state);

    return {
      users: state.Users.users,
      laundrylist: state.ecommerce.laudrylist,
      workersList: state.LaundryWorkers.workers,
      totalWorkersCount: state.LaundryWorkers.totalWorkers
    }
  })
  const [error, setError] = useState(initailStateOfErrors);
  const [userData, setUserData] = useState({});
  const [modal, setModal] = useState(false);
  const [workers, setWorkers] = useState([]);
  const [form, setForm] = useState(initailFormData);
  const [editIndex, setEditIndex] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [workerToDelete, setWorkerToDelete] = useState(null);
  const [totalWorkers, setTotalWorkers] = useState(0)
  const [laundries, setLaundries] = useState([])
  const [selectedStatus, setSelectedStatus] = useState('')
  const [selectedWorker, setSelectedWorker] = useState(null);
  const dispatch = useDispatch()
  // const [statusDisabled, setStatusDisabled] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const [statusUpdating, setStatusUpdating] = useState(false)

  const hasCreatePermission = () => userData?.permissions?.allPermissions == true || userData?.permissions?.laundryWorker.create == true;
  const hasUpdatePermission = () => userData?.permissions?.allPermissions == true || userData?.permissions?.laundryWorker.update == true;

  // Toggle Modal
  const toggleModal = () => setModal(!modal);
  const toggleDeleteModal = (worker) => {
    setWorkerToDelete(worker); // Store the worker to be deleted
    setDeleteModal(!deleteModal); // Toggle the delete modal
  };

  const toggleEditModal = () => {
    setEditModal(!editModal); // Toggle the delete modal
  };
  const fileInputRef = useRef(null);
  const imageInputRef = useRef(null);

  const handleImageUploadClick = () => {
    if (imageInputRef.current) {
      imageInputRef.current.click();
    }
  };

  const handleImageFileRemove = () => {
    if (imageInputRef.current) {
      imageInputRef.current.value = '';
      setForm({ ...form, faceImage: null })
    }
  }

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleFileRemove = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
      setForm({ ...form, healthCertificate: null })
    }
  }
  useEffect(() => {
    if (users.length > 0) {
      setUserData(users[0]);
    }
  }, [users]);

  // Handle Input Change
  const handleChange = (e) => {
    setError(initailStateOfErrors)
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const validateMobile = (number) => {
    let regex;
    let valid = true
    if (phCode == 91) {
      // India: 10 digits, starting with 6, 7, 8, or 9
      regex = /^[6-9]\d{9}$/;
    } else if (phCode == 966) {
      // Saudi Arabia: 9 digits, or 10 if starting with 0
      regex = /^0?\d{9}$/;
    } else {
      // Handle other cases or default to invalid
      valid = false
      setError({ ...error, mobile: "Invalid country code." });
    }

    if (number === "" || regex.test(number)) {
      valid = true
      setError({ ...error, mobile: '' });
    } else {
      valid = false
      setError(
        {
          ...error, mobile: phCode == 91
            ? "Mobile number must be 10 digits long and start with 6, 7, 8, or 9."
            : "Mobile number must be 9 digits long, or 10 if starting with 0."
        }
      );
    }

    return valid
  };

  const fetchWorkers = async () => {
    setLoading(true)
    try {
      dispatch(getLaundryWorkers({
        page: currentPage,
        pageSize: rowsPerPage,
        searchText,
        status: selectedStatus
      }))
    } catch (error) {
      toastr.error('Error fetching workers');
    } finally {
      setLoading(false)
    }
  };

  const handleUpload = (e, faceImage) => {
    const file = e.target.files[0];
    if (!file) return;
    if(faceImage){
      setForm({ ...form, faceImage: file })
    } else {
      setForm({ ...form, healthCertificate: file })
    }
  }

  // Handle Add or Edit Worker
  const handleSave = async () => {
    setLoading(true);
    try {
      if (!form.name || !form.mobile || !form.laundry) {
        setLoading(false)
        return toastr.error("Name, laundry, and mobile fields are required")
      }
      let isValidPhone = await validateMobile(form.mobile)
      if (!isValidPhone) {
        if (editModal == true && editIndex != null) {
          toggleEditModal()
        }
        setLoading(false)
        return
      }
      const formData = new FormData();
     
      formData.append('file', form.healthCertificate);
      formData.append('name', form.name);
      formData.append('mobile', form.mobile);
      formData.append('email', form.email);
      formData.append('address', form.address);
      formData.append('laundry', form.laundry);
      formData.append('status', form.status);
      let response;
      if (editIndex !== null) {
        formData.append('workerId', workers[editIndex]._id); // Pass workerId when updating
        if(!workers[editIndex]?.deqaID){
          formData.append('image', form.faceImage);
        }
        response = await postWeb('/laundryWorkers/update', formData, true); // Update request
      } else {
        formData.append('image', form.faceImage);
        response = await postWeb('/laundryWorkers/create', formData, true); // Create request
      }
      if (response?.statusCode == 200) {
        fetchWorkers();
        toastr.success(response.msg);
      } else {
        fetchWorkers();
        toastr.error(response.msg);
      }

      resetForm();
      if (editModal) toggleEditModal()
      toggleModal(); // Close the modal after save
    } catch (error) {
      fetchWorkers();
      console.log('error', error);
      
      toastr.error('Error saving worker');
    } finally {
      setLoading(false);
    }
  };

  const updateStatus = async (payload, index) => {
    setStatusUpdating(true)
    try {
      const response = await postWeb('/laundryWorkers/update', payload);

      if (response?.statusCode == 200) {
        setWorkers(prev=>{
          const updatedWorkers = [...prev];
          updatedWorkers[index].status = payload.status;
          return updatedWorkers;
        })
        toastr.success("Status updated successfully");
      } else {
        toastr.error("Error updating status");
      }
    } catch (error) {
      console.log('errro', error);
      toastr.error('Error updating status');
    } finally {
      setStatusUpdating(false)
    }
  }

  // Define the debounced function
  const debouncedUpdateStatus = useCallback(
    _.debounce((workerId, payload, index) => {
      setForm
      updateStatus({ workerId, ...payload }, index);
    }, 300),
    []
  );
  // Reset Form and Edit Index
  const resetForm = () => {
    setForm({ name: '', mobile: '', email: '', address: '', healthCertificate: null, status: true });
    setEditIndex(null);
  };

  // Handle Edit Button Click
  const handleEdit = (index) => {
    setForm({ ...workers[index], healthCertificate: workers[index]?.healthCertificate, laundry: workers[index]?.laundry?._id });
    setSelectedWorker(workers[index])
    setEditIndex(index);
    toggleModal();
  };

  // Handle Delete Worker
  const handleDelete = (worker) => {
    toggleDeleteModal(worker); // Show the delete confirmation modal
  };

  const deleteWorker = async () => {
    setLoading(true)
    try {
      const payload = { workerId: workerToDelete._id };
      const response = await postWeb('/laundryWorkers/delete', payload);
      if (response?.statusCode == 200) {
        fetchWorkers(); // Refresh worker list
        toastr.success(response.msg);
      } else {
        toastr.error(response.msg);
      }
    } catch (error) {
      toastr.error('Error deleting worker');
    } finally {
      setLoading(false)
      setDeleteModal(false); // Close the modal after deletion
    }
  }

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page); // Update the current page state
  };

  // Handle rows per page change
  const handleRowsPerPageChange = (newPerPage, page) => {
    setRowsPerPage(newPerPage); // Update the rows per page state
    setCurrentPage(page); // Optionally reset to page 1 when rows per page changes
  };

  // Columns for DataTable
  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      width: "50px",
    },
    {
      name: "Name",
      selector: "name",
      sortable: true,
    },
    {
      name: "Mobile",
      selector: row => row.mobile || "N/A", // Show 'N/A' if mobile is missing
      sortable: true,
    },
    {
      name: "Email",
      selector: row => row?.email || "N/A", // Show 'N/A' if email is missing
      sortable: true,
    },
    {
      name: "Laundry",
      selector: row => row?.laundry?.nameEn || "N/A", // Show 'N/A' if email is missing
      sortable: true,
    },
    {
      name: "Address",
      selector: row => row?.address || "N/A", // Show 'N/A' if address is missing
      sortable: true,
      wrap: true,
    },
    {
      name: "Health Certificate",
      selector: row =>
        row.healthCertificate ? (
          <Button
            className="d-flex align-items-center justify-content-between"
            color="primary"
            outline
            size="sm"
            onClick={() => window.open(row?.healthCertificate, "_blank")}
          >
            <span className="">Download</span>
            <span className="mr-2">
              <i className="bx bx-download bx-xs"></i>
            </span>
          </Button>
        ) : (
          "Not Uploaded"
        ),
    },
    // {
    //   name: 'Status',
    //   selector: (row) => (row.status ? 'Active' : 'Inactive'),
    //   sortable: true,
    // },
    ...(hasUpdatePermission() == true
      ? [
        {
          name: "Status",
          cell: (row, index) => (
            <>
              <div className="mb-3">
                <div className="d-flex align-items-center">
                  <div className="form-check form-switch form-switch-lg">
                    <input
                      disabled={statusUpdating}
                      type="checkbox"
                      className="form-check-input form-slider-color-primary"
                      checked={row.status == true ? true : false}
                      onChange={e => {
                        const newStatus = !row?.status
                        debouncedUpdateStatus(row?._id, {...row, status: newStatus }, index)
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* <Button
                  disabled={isLoading}
                  color="warning"
                  size="sm"
                  className="me-2"
                  onClick={() => handleEdit(index)}
                >
                  <i className="bx bx-edit-alt"></i>
                </Button>
                <Button
                  disabled={isLoading}
                  color="danger"
                  size="sm"
                  onClick={() => handleDelete(row)}
                >
                  <i className="bx bx-trash"></i>
                </Button> */}
            </>
          ),
        },
        {
          name: "Edit",
          selector: (row, index) =>
            <Button
              disabled={isLoading}
              color="warning"
              size="sm"
              className="me-2"
              onClick={() => handleEdit(index)}
            >
              <i className="bx bx-edit-alt"></i>
            </Button>
        },
      ]
      : []),
  ]

  const debouncedFetchWorkers = _.debounce(fetchWorkers, 500);

  useEffect(() => {
    dispatch(getLaundrylist({ status: true }));
    fetchWorkers();
  }, []);

  useEffect(() => {
    setLaundries(laundrylist)
    setTotalWorkers(totalWorkersCount)
    setWorkers(workersList)
  }, [laundrylist, workersList, totalWorkersCount, laundrylist])



  useEffect(() => {
    if (!modal) {
      resetForm()
    }
  }, [modal])

  useEffect(() => {
    if (searchText || selectedStatus) {
      setCurrentPage(1)
      setRowsPerPage(10)
      debouncedFetchWorkers();
      return () => {
        debouncedFetchWorkers.cancel();
      };
    }
    else {
      fetchWorkers();
    }
  }, [searchText, currentPage, rowsPerPage, selectedStatus]);

  if (userData?.permissions?.allPermissions == true || userData?.permissions?.laundryWorker?.read == true) {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Masters | Laundry Worker</title>
          </MetaTags>

          <div className="container-fluid">
            <Breadcrumbs
              title={props.t("Masters")}
              breadcrumbItem={props.t("Laundry Worker Master")}
            />
            {hasCreatePermission() == true &&
              <Button disabled={isLoading} className="mb-4" color="primary" onClick={toggleModal} style={{ float: 'right' }}>
                Add New Laundry Worker
              </Button>
            }
            <div className="mb-4 d-flex align-items-center w-full justify-content-between">
              <div className="w-50 gap-5 d-flex">
                <Input
                  disabled={isLoading}
                  type="text"
                  placeholder="Search by Name, Mobile, or Email"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  className='w-full p-2 border rounded'
                />
                <div className="d-flex justify-content-between w-100 align-items-center gap-3">
                  <Label for="vehicleType">Status</Label>
                  <Input
                    disabled={isLoading}
                    type="select"
                    name="type"
                    id="vehicleType"
                    value={selectedStatus}
                    onChange={e => setSelectedStatus(e.target.value)}
                  >
                    <option value="">Choose</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </Input>
                </div>
              </div>

            </div>
          </div>

          <DataTable
            columns={columns}
            data={workers}
            progressPending={isLoading}
            pagination
            paginationServer
            paginationTotalRows={totalWorkers}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            progressComponent={<Skeleton height={50} count={5} />}
          />

          {/* Add/Edit Modal */}
          <Modal isOpen={modal} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>
              {editIndex !== null ? 'Edit Laundry Worker' : 'Add New Laundry Worker'}
            </ModalHeader>
            <ModalBody>
              <Form>
                <FormGroup className='mb-2'>
                  <Label for="name">Name</Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    value={form.name}
                    onChange={handleChange}
                    placeholder="Enter Name"
                  />
                </FormGroup>
                <FormGroup className='mb-2'>
                  <Label for="mobile">Mobile</Label>
                  <Input
                    type="text"
                    name="mobile"
                    id="mobile"
                    value={form.mobile}
                    onChange={handleChange}
                    placeholder="Enter Mobile"
                    invalid={error?.mobile.length ? true : false}
                  />
                  {error?.mobile.length ? <div className="text-danger">{error.mobile}</div> : null}
                </FormGroup>
                <FormGroup className='mb-2'>
                  <Label for="email">Email</Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    value={form.email}
                    onChange={handleChange}
                    placeholder="Enter Email"
                  />
                </FormGroup>
                <FormGroup className="mb-3 position-relative">
                  <Label for="laundry">Laundry</Label>
                  <Input
                    className='absolute'
                    disabled={isLoading}
                    type="select"
                    name="laundry"
                    // id="laundry"
                    value={form.laundry}
                    onChange={handleChange}
                  >
                    <option className='w-25 position-absolute' value={''}>Select</option>
                    {laundries.map(laundry => (
                      <option key={laundry?._id} value={laundry?._id}>{laundry?.nameEn}</option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup className='mb-2'>
                  <Label for="address">Address</Label>
                  <Input
                    type="text"
                    name="address"
                    id="address"
                    value={form.address}
                    onChange={handleChange}
                    placeholder="Enter Address"
                  />
                </FormGroup>
              </Form>
            </ModalBody>
          </Modal>
        </div>

        {/* Add/Edit Modal */}
        <Modal isOpen={modal} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            {editIndex !== null ? 'Edit Laundry Worker' : 'Add New Laundry Worker'}
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup className='mb-2'>
                <Label for="name">Name</Label>
                <div className="d-flex align-items-center gap-2">
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    value={form.name}
                    onChange={handleChange}
                    placeholder="Enter Name"
                    disabled={editIndex !== null && selectedWorker?.deqaID}
                  />
                  {
                    editIndex !== null && selectedWorker?.deqaID
                      ? (
                        <>
                          <i
                            id="infoTooltip_name"
                            className="fa fa-info-circle cursor-pointer text-primary font-size-18"
                          ></i>
                          <UncontrolledTooltip
                            placement="top"
                            target="infoTooltip_name"
                          >
                            Name cannot be changed as it is linked with DEQA
                          </UncontrolledTooltip>
                        </>
                      )
                      : null
                  }
                </div>
              </FormGroup>
              <FormGroup className='mb-2'>
                <Label for="mobile">Mobile</Label>
                <Input
                  type="text"
                  name="mobile"
                  id="mobile"
                  value={form.mobile}
                  onChange={handleChange}
                  placeholder="Enter Mobile"
                  invalid={error?.mobile.length ? true : false}
                />
                {error?.mobile.length ? <div className="text-danger">{error.mobile}</div> : null}
              </FormGroup>
              <FormGroup className='mb-2'>
                <Label for="email">Email</Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  value={form.email}
                  onChange={handleChange}
                  placeholder="Enter Email"
                />
              </FormGroup>
              <FormGroup className="mb-3 position-relative">
                <Label for="laundry">Laundry</Label>
                <div className="d-flex align-items-center gap-2">
                  <Input
                    className='absolute'
                    type="select"
                    name="laundry"
                    // id="laundry"
                    value={form.laundry}
                    onChange={handleChange}
                    disabled={editIndex !== null && selectedWorker?.deqaID}
                  >
                    <option className='w-25 position-absolute' value={''}>Select</option>
                    {laundries.map(laundry => (
                      <option key={laundry?._id} value={laundry?._id}>{laundry?.nameEn}</option>
                    ))}
                  </Input>
                  {
                    editIndex !== null && selectedWorker?.deqaID
                      ? (
                        <>
                          <i
                            id="infoTooltip_laundry"
                            className="fa fa-info-circle cursor-pointer text-primary font-size-18"
                          ></i>
                          <UncontrolledTooltip
                            placement="top"
                            target="infoTooltip_laundry"
                          >
                            Laundry cannot be changed as worker is linked with DEQA
                          </UncontrolledTooltip>
                        </>
                      )
                      : null
                  }  
                </div>
              </FormGroup>
              <FormGroup className='mb-2'>
                <Label for="address">Address</Label>
                <Input
                  type="text"
                  name="address"
                  id="address"
                  value={form.address}
                  onChange={handleChange}
                  placeholder="Enter Address"
                />
              </FormGroup>
             {editIndex == null   ? <FormGroup
                style={{
                  width: '115px'
                }}
                className="my-3 form-check form-switch form-switch-lg d-flex justify-content-between p-0">
                <Label for="address">Status</Label>
                <input
                  disabled={statusUpdating}
                  type="checkbox"
                  className="form-check-input form-slider-color-primary"
                  defaultChecked={form.status == true ? true : false}
                  onChange={e => setForm({ ...form, status: e.target.checked })}
                />
              </FormGroup> :''}
              <Row className='mb-2'>
                <Col className='mb-2'>
                  <Label for="healthCertificate">Health Certificate</Label>
                  <input
                    type="file"
                    accept="image/*,application/pdf,.doc,.docx"
                    onChange={(e) => handleUpload(e, false)}
                    ref={fileInputRef}
                    hidden
                  />
                  <Button
                    color="primary"
                    className="p-2 text-left  d-flex justify-content-between align-items-center w-max"
                    onClick={handleUploadClick}
                  >
                    <i className="fa fa-upload h-4 w-4 px-1 mr-1"></i>
                    <span>
                      {editIndex !== null ? "Change" : "Upload image"}
                    </span>
                  </Button>
                  {form?.healthCertificate ?
                    <>
                      <div className="w-full" title={form?.healthCertificate?.name || form?.healthCertificate?.split('/').pop()}>
                        <p className='mr-0 mt-2 '>
                          File Name:
                        </p>
                        <div className=' d-flex align-items-center'>

                          <strong className="mb-0 text-truncate mr-2">
                            {form?.healthCertificate?.name?.length > 25
                              ? `${form?.healthCertificate?.name.slice(0, 25)}...`
                              : (form?.healthCertificate?.name || form?.healthCertificate?.split('/').pop().slice(0, 25))}
                          </strong>

                          <i className={`bx bx-sm ${(() => {
                            const fileName = form?.healthCertificate?.name || form?.healthCertificate?.split('/').pop();
                            const fileExtension = fileName?.split('.').pop()?.toLowerCase();

                            // If file extension exists, return the corresponding icon class, otherwise default to a file icon
                            return fileExtension ? `bxs-file-${fileExtension}` : 'bxs-file';
                          })()}`}></i>

                          {editIndex == null && <i title="Remove" onClick={handleFileRemove} className="fa fa-times-circle text-danger cursor-pointer h-4 w-4"></i>}
                          <i />
                        </div>
                      </div>

                    </>
                    :
                    null
                  }
                </Col>

                {/* upload face Image  */}
                  <Col className='mb-2'>
                    <Label for="faceImage">Face Image</Label>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleUpload(e, true)}
                      ref={imageInputRef}
                      hidden
                    />
                    <div className="d-flex align-items-center gap-2">
                      <Button
                        color="primary"
                        className="p-2 text-left  d-flex justify-content-between align-items-center w-max"
                        onClick={handleImageUploadClick}
                        disabled={editIndex !== null && selectedWorker?.deqaID}
                      >
                        <i className="fa fa-upload h-4 w-4 px-1 mr-1"></i>
                        <span>
                          {editIndex !== null ? "Change" : "Upload image"}
                        </span>
                      </Button>
                      {
                        editIndex !== null && selectedWorker?.deqaID
                          ? (
                            <>
                              <i
                                id="infoTooltip_faceImage"
                                className="fa fa-info-circle cursor-pointer text-primary font-size-18"
                              ></i>
                              <UncontrolledTooltip
                                placement="top"
                                target="infoTooltip_faceImage"
                              >
                                Face Image cannot be changed as worker is linked with DEQA
                              </UncontrolledTooltip>
                            </>
                          )
                          : null
                      }
                    </div>
                    {
                      form?.faceImage ?
                        <>
                          <div className="w-full" title={form?.faceImage?.name || form?.faceImage?.split('/').pop()}>
                            <p className='mr-0 mt-2 '>
                              File Name:
                            </p>
                            <div className=' d-flex align-items-center'>

                              <strong className="mb-0 text-truncate mr-2">
                                {form?.faceImage?.name?.length > 25
                                  ? `${form?.faceImage?.name.slice(0, 25)}...`
                                  : (form?.faceImage?.name || form?.faceImage?.split('/').pop().slice(0, 25))}
                              </strong>

                              <i className={`bx bx-sm ${(() => {
                                const fileName = form?.faceImage?.name || form?.faceImage?.split('/').pop();
                                const fileExtension = fileName?.split('.').pop()?.toLowerCase();

                                // If file extension exists, return the corresponding icon class, otherwise default to a file icon
                                return fileExtension ? `bxs-file-${fileExtension}` : 'bxs-file';
                              })()}`}></i>

                              {editIndex == null && <i title="Remove" onClick={handleImageFileRemove} className="fa fa-times-circle text-danger cursor-pointer h-4 w-4"></i>}
                              <i />
                            </div>
                          </div>

                        </>
                        :
                        null
                    }
                  </Col>
              </Row>
              {
                editIndex !== null && selectedWorker?.deqaID
                  ? (
                    <>
                      <SectionTitle title="Face Authentication" />
                      <Row className='mb-2'>
                        <Col lg="3" className="d-flex">
                            <div className="d-flex align-items-center gap-2">
                              <label
                                htmlFor="horizontal-status-Input"
                                className="status-label mt-2"
                              >Deqa ID :- </label>
                                <span className="">{selectedWorker?.deqaID}</span>
                            </div>
                        </Col>  
                        <Col lg="4" className="d-flex">
                              <div className="d-flex align-items-center gap-2">
                                <img src={selectedWorker?.faceImage} alt="faceImage" className="img-thumbnail avatar-sm rounded-circle" />
                                <div>
                                      <a
                                        href={selectedWorker?.faceImage}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-primary text-decoration-underline">
                                        View Image
                                      </a>
                                </div>
                              </div>
                        </Col>
                        <Col lg="5" className="d-flex">
                        {
                          deqaUrl && selectedWorker?.deqaID &&
                            <div className="d-flex align-items-center">
                              <a 
                                href={deqaUrl + "/admin/o/employees/edit/" + selectedWorker?.deqaID}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-primary text-decoration-underline">
                                <img src={deqaImg} alt="deqa" height="40" width="40" />View Employee Details
                              </a>
                            </div>
                        }
                        </Col>
                      </Row>
                    </>
                  )
                  : null
              }

            </Form>
          </ModalBody>
          <ModalFooter>
            <Button disabled={isLoading} color="secondary" onClick={toggleModal}>
              Cancel
            </Button>
            <Button disabled={isLoading} color="primary" onClick={editIndex != null ? toggleEditModal : handleSave}>
              Save
            </Button>
          </ModalFooter>
        </Modal>

        {/* Delete Confirmation Modal */}
        <Modal isOpen={deleteModal} toggle={() => setDeleteModal(false)}>
          <ModalHeader toggle={() => setDeleteModal(false)}>
            Confirm Deletion
          </ModalHeader>
          <ModalBody>
            Are you sure you want to delete this worker?
          </ModalBody>
          <ModalFooter>
            <Button disabled={isLoading} color="secondary" onClick={() => setDeleteModal(false)}>
              Cancel
            </Button>
            <Button disabled={isLoading} color="danger" onClick={deleteWorker}>
              Delete
            </Button>
          </ModalFooter>
        </Modal>
        {/* Update Confimation modal */}
        <Modal isOpen={editModal} toggle={() => setEditModal(false)}>
          <ModalHeader toggle={() => setEditModal(false)}>
            Confirm Updation
          </ModalHeader>
          <ModalBody>
            Are you sure you want to update this worker?
          </ModalBody>
          <ModalFooter>
            <Button disabled={isLoading} color="secondary" onClick={() => setEditModal(false)}>
              Cancel
            </Button>
            <Button disabled={isLoading} color="primary" onClick={handleSave}>
              Save
            </Button>
          </ModalFooter>
        </Modal>

      </React.Fragment>

    )
  } else {
    return <PagesNoPermission />
  }
};

LaundryWorkers.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(LaundryWorkers);
